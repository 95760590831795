import moment from "moment";

// @ts-expect-error TS(2307): Cannot find module 'moment/locale/de' or its corre... Remove this comment to see the full error message
import localeDE from "moment/locale/de";

// @ts-expect-error TS(2307): Cannot find module 'moment/locale/pl' or its corre... Remove this comment to see the full error message
import localePL from "moment/locale/pl";

const locales = {
  pl: localePL,
  de: localeDE,
};

export const getMoment = (locale: any) => {

  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  moment.updateLocale(locale, locales[locale]);
  return moment;
};
